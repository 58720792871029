const tables = $('.js-stacked-table');
const rowsWithHref = $('.js-stacked-table tr[data-href]');

function addExpandedTrigger() {
  tables.each(function () {
    // Find all rows
    $('tr', this).each(function () {
      const $row = $(this);

      // Prepare rows
      $row.attr('aria-expanded', 'false');

      // first column
      $('td:first-child', this).click(function () {
        if ($row.attr('aria-expanded') === 'true') {
          $row.attr('aria-expanded', 'false');
          return;
        }

        $row.attr('aria-expanded', 'true');
      })
    });
  });
}

function removeExpandedTrigger() {
  tables.each(function () {
    // Find all rows
    $('tr', this).each(function () {
      $(this).removeAttr('aria-expanded');

      $('td:first-child', this).off('click')
    })
  })
}

function addRowLinks() {
  rowsWithHref.each(function () {
    $(this).attr('role', 'link');

    $(this).on('click', function () {
      window.location = $(this).data('href');
    })
  });
}

function removeRowLinks() {
  rowsWithHref.each(function () {
    $(this).removeAttr('role');

    $(this).off('click');
  });
}

/**
 * Handle init
 */
if(Foundation.MediaQuery.atLeast('large')) {
  addRowLinks();
}

if(Foundation.MediaQuery.upTo('medium')) {
  addExpandedTrigger();
}

/**
 * Handle resize
 */
$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
  if(Foundation.MediaQuery.atLeast('large')) {
    addRowLinks();
    removeExpandedTrigger();
  }

  if(Foundation.MediaQuery.upTo('medium')) {
    removeRowLinks();
    addExpandedTrigger();
  }
});
