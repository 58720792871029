import $ from 'jquery';
import 'foundation-sites';
import 'what-input';
import 'slick-carousel';
import 'tablesorter';
import 'foundation-datepicker';
import 'foundation-datepicker/js/locales/foundation-datepicker.de.js';
import 'devbridge-autocomplete';
import 'ajaxq';
import 'featherlight';
import 'select2';
import 'js-cookie';
import 'jquery-doubletaptogo';
import '@fancyapps/fancybox'

// Components
import './components/stacked-tables.js'

// Export jQuery to window. Global will be convert to the window object.
global.$ = global.jQuery = $;

$(document).foundation();

$(function(){

  $('.js-carousel').slick({
    arrows: false,
    mobileFirst: true,
    swipeToSlide: true
  });

  // Arrow buttons

  $('.carousel-prev').click(e => {
    $('#' + e.currentTarget.dataset.carousel).slick('slickPrev');
  });

  $('.carousel-next').click(e => {
    $('#' + e.currentTarget.dataset.carousel).slick('slickNext');
  });

  // Double Tap for navigation on touch devices
  $('.js-mega-menu').doubleTapToGo();


  // Mega menu
  let menuTimeout;

  $('.js-mega-menu > li.has-submenu > a').on('mouseenter', function () {
    const $menuItem = $(this).parent();
    $menuItem.addClass('is-enter');

    menuTimeout = setTimeout(function () {
      if ($menuItem.hasClass('is-enter')) {
        $menuItem.addClass('is-open');
      }
    }, 250);

  }).on('mouseleave', function () {
    // If mouse move fast over menu don't open menu
    const $menuItem = $(this).parent();

    if(!$menuItem.hasClass('is-open')) {
      clearTimeout(menuTimeout);
      $menuItem.removeClass('is-enter');
    }
  });

  $('.js-mega-menu > li.has-submenu').on('mouseleave', function () {
    const $menuItem = $(this);

    $menuItem.removeClass('is-open');

    setTimeout(function () {
      if (!$menuItem.hasClass('is-open')) {
        $menuItem.removeClass('is-enter');
      }
    }, 250);
  });

  // Tablesorter

  $(".js-tablesorter").tablesorter();

  // Datepicker

  $('input.js-date').fdatepicker({
    format: 'dd.mm.yyyy',
    disableDblClickSelection: true,
    leftArrow:'<svg class="icon icon-arrow black flip" aria-hidden="true" role="img"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow"></use></svg>',
    rightArrow:'<svg class="icon icon-arrow black" aria-hidden="true" role="img"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow"></use></svg>',
    language: 'de',
  });

  // Autocomplete

  const countries = [
    { value: 'Andorra', data: 'AD' },
    { value: 'Zimbabwe', data: 'ZZ' }
  ];

  $('.js-autocomplete').autocomplete({
    lookup: countries
  });

  // Select2
  $('.js-select2').select2();

  // Fancybox
  $("[data-fancybox]").fancybox({
    btnTpl: {
    // Arrows
    arrowLeft:
      `<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">
        <svg class="icon icon-arrow flip" aria-hidden="true" role="img">
	        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow"></use>
        </svg>
      </button>`,

    arrowRight:
      `<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">
        <svg class="icon icon-arrow" aria-hidden="true" role="img">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow"></use>
          </svg>
      </button>`
    },

    buttons: [
      //"zoom",
      //'share',
      //'slideShow',
      //'fullScreen',
      //'download',
      //"thumbs",
      "close"
  ],
  });
});
